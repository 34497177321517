const determineQuizzes = (formData) => {
    let pemaQuiz, mimaQuiz;

    // Logic to determine quizzes based on formData
    const studentClass = formData['class'];
    const majorSubject = formData['majorSubjects'];

    if (['6', '7', '8'].includes(studentClass)) {
    //    pemaQuiz = 'pema1';
        mimaQuiz = 'mima1';
    } else if (['9', '10'].includes(studentClass)) {
        pemaQuiz = 'pema2';
        mimaQuiz = 'mima2';
    } else {
    //    pemaQuiz = 'pema2';
        switch (majorSubject) {
            case 'commerce':
                mimaQuiz = 'mima3_1';
                break;
            case 'arts_humanities':
                mimaQuiz = 'mima3_2';
                break;
            case 'pcb':
                mimaQuiz = 'mima3_3';
                break;
            case 'pcm':
                mimaQuiz = 'mima3_4';
                break;
            default:
                mimaQuiz = 'No quiz for you'; // Default or error case
        }
    }

    return { pemaQuiz, mimaQuiz };
};

export default determineQuizzes;
