import React from 'react';
import NavigationButtons from './NavigationButtons';



const QuizNavigation = ({
  currentPage,
  totalPages,
  setCurrentPage,
  handleSubmit,
  isCurrentQuestionAnswered
}) => {
  const showSubmitButton = currentPage === totalPages;

  return (
    <NavigationButtons
      currentPage={currentPage}
      totalPages={totalPages}
      onPrevious={() => setCurrentPage(Math.max(1, currentPage - 1))}
      onNext={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
      onSubmit={handleSubmit}
      showSubmitButton={showSubmitButton}
      isCurrentQuestionAnswered={isCurrentQuestionAnswered}
    />
  );
};

export default QuizNavigation;
