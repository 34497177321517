import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const Header = ({ title }) => {
  return (
<AppBar position="static" style={{ background: '#009688', boxShadow: 'none' }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center', color: 'white' }}>
          {title || "Career-9 Registration"}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
