// ProgressBar.js
import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';

const ProgressBar = ({ currentQuestionIndex, totalQuestions }) => {
  const progressPercent = (currentQuestionIndex / totalQuestions) * 100;

  return (
    <Box sx={{ width: '100%', marginBottom: 2 }}>
      <LinearProgress variant="determinate" value={progressPercent} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
        <Typography variant="body2">
          Question: {currentQuestionIndex}/{totalQuestions}
        </Typography>
        <Typography variant="body2">
          {Math.round(progressPercent)}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
