// src/components/CustomButton.js
import React from 'react';
import { Button } from '@mui/material';

const buttonStyle = {
  background: 'linear-gradient(45deg, #000000 30%, #434343 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #434343 30%, #000000 90%)',
  },
};

const CustomButton = ({ label, onClick }) => {
  return (
    <Button variant="contained" sx={buttonStyle} onClick={onClick}>
      {label}
    </Button>
  );
};

export default CustomButton;
