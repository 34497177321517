import React, { useState } from 'react'; // Add the 'useState' import
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import FormFieldRenderer from './FormFieldRenderer';
import formFields from '../data/registrationFormConfig.json';
import { submitToSupabase } from '../utils/SupabaseUtility';
import Header from './Header';
import useFormState from '../hooks/useFormState'; 
import determineQuizzes from '../utils/determineQuizzes'; 
import CustomButton from './CustomButton'; // Import the CustomButton component

const RegistrationForm = () => {
    const isFieldDisabled = (field) => {
        return field.disableForClasses && formData['class'] && field.disableForClasses.includes(formData['class']);
    };

    const { formData, formErrors, handleChange, validateForm } = useFormState({}, formFields, isFieldDisabled);
    const [isLoading, setIsLoading] = useState(false);
    const [quizInfo, setQuizInfo] = useState(null); // To store quiz information
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        setTimeout(async () => {
            const response = await submitToSupabase(formData);
            setIsLoading(false);

            if (response) {
                const { mimaQuiz } = determineQuizzes(formData);
                if (mimaQuiz) {
                    // Store the quiz information
                    setQuizInfo({
                        quiz: mimaQuiz,
                        name: formData['name'],
                    });
                }
            } else {
                // Set isError to true for showing the error message
                setIsError(true);
            }
        }, 1000);
    };

    return (
        <>
            <Header title="Career-9 Registration" />
            {!quizInfo ? ( // Display the registration form if quizInfo is not available
                <form onSubmit={handleSubmit}>
                    {formFields.map((field) => (
                        <FormFieldRenderer 
                            key={field.id} 
                            field={field} 
                            formData={formData} 
                            handleChange={handleChange}
                            error={formErrors[field.id]}
                            disabled={isFieldDisabled(field)}
                        />
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                            Submit
                        </Button>
                    </Box>
                </form>
            ) : ( // Display a thank you screen with quiz information if quizInfo is available
                <Box sx={{ textAlign: 'center', minHeight: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5">Thank you, {quizInfo.name}!</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        You have completed the registration. Please proceed to take your analysis.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        {isLoading ? (
                            <CircularProgress color="primary" />
                        ) : (
                            <CustomButton label="Start Analysis" onClick={() => window.location.href = `/${quizInfo.quiz}`} />
                        )}
                    </Box>
                </Box>
            )}
            {isError && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography variant="h5" color="error">
                        Form Submission Error
                    </Typography>
                    <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                        Failed to submit data. Please try again and make sure all required fields are filled.
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default RegistrationForm;
