// src/components/DialogBox.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const getDialogStyle = (type) => {
  switch (type) {
    case 'error':
      return { backgroundColor: '#ffebee', color: '#b71c1c' }; // Soft red
    case 'warning':
      return { backgroundColor: '#fff3e0', color: '#ff6f00' }; // Soft orange
    case 'info':
      return { backgroundColor: '#e3f2fd', color: '#1e88e5' }; // Soft blue
    case 'success':
      return { backgroundColor: '#e8f5e9', color: '#2e7d32' }; // Soft green
    default:
      return { backgroundColor: '#fafafa', color: '#212121' }; // Neutral grey
  }
};

const DialogBox = ({ isOpen, handleClose, title, content, buttonText, type }) => {
  const dialogStyle = getDialogStyle(type);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      PaperProps={{
        style: {
          ...dialogStyle,
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)', // Added shadow for depth
        },
      }}
      transitionDuration={{ enter: 300, exit: 200 }}
    >
      <DialogTitle id="dialog-title" style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description" style={{ margin: '8px 0', fontSize: '1rem' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start', padding: '8px 24px' }}>
        <Button onClick={handleClose} variant="contained" color="primary" style={{ fontWeight: 'bold' }}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
