import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

const FormFieldRenderer = ({ field, formData, handleChange, error, disabled }) => {
    const renderError = () => {
        return error ? (
            <Typography color="error" variant="caption">{error}</Typography>
        ) : null;
    };

// Base styles for all the form elements
const baseStyles = {
  '& .MuiInputLabel-root': { fontSize: '1.2rem' }, // Label text
  '& .MuiInputBase-root': { fontSize: '1.2rem' }, // Input text
  '& .MuiSelect-select': { fontSize: '1.2rem' }, // Select field
  '& .MuiTypography-root': { fontSize: '1.2rem' }, // Typography
  '& .MuiCheckbox-root': { fontSize: '1.2rem' }, // Checkbox
  '& .MuiFormControlLabel-label': { fontSize: '1.2rem' }, // FormControlLabel
  // Apply additional styles if needed
};

  
    switch (field.type) {

        case 'text':
        case 'email':
        case 'date':
        case 'textarea':
            return (
                <TextField
                    sx={baseStyles}
                    label={field.label}
                    type={field.type}
                    variant="outlined"
                    name={field.id}
                    placeholder={field.placeholder || ''}
                    value={formData[field.id] || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!error}
                    multiline={field.type === 'textarea'}
                    rows={field.type === 'textarea' ? 4 : 1}
                    InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                    inputProps={field.type === 'date' ? { max: new Date().toISOString().split('T')[0] } : (field.inputProps || {})}
                />
            );


            case 'select':
              return (
                <>
                  <FormControl fullWidth margin="normal" error={!!error} disabled={disabled}>
                    <InputLabel sx={{ fontSize: '1.2rem' }}>{field.label}</InputLabel>
                    <Select
                      value={formData[field.id] || ''}
                      onChange={handleChange}
                      name={field.id}
                      label={field.label}
                      sx={{ '& .MuiSelect-select': { fontSize: '1.2rem' } }} // Apply the font size to the selected value
                      MenuProps={{
                        PaperProps: {
                          sx: { '& .MuiMenuItem-root': { fontSize: '1.2rem' } } // Apply the font size to menu items
                        }
                      }}
                    >
                      {field.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {renderError()}
                </>
              );
            
            

              case 'multiSelect': {
                const maxSelections = 3;
                const selectedOptions = formData[field.id] || [];
                const isMaxSelected = selectedOptions.length >= maxSelections;
                const isOtherSelected = selectedOptions.includes('other');
              
                // Function to map selected values to their corresponding labels
                const renderSelectedValues = (selected) => {
                  return selected.map(value => {
                    const selectedOption = field.options.find(option => option.value === value);
                    return selectedOption ? selectedOption.label : value;
                  }).join(', ');
                };
              
                return (
                  <>
                    <FormControl key={field.id} fullWidth margin="normal" error={!!error}>
                      <InputLabel sx={{ fontSize: '1.2rem' }}>{field.label}</InputLabel>
                      <Select
                        multiple
                        value={selectedOptions}
                        onChange={handleChange}
                        name={field.id}
                        label={field.label}
                        sx={{ '& .MuiSelect-select': { fontSize: '1.2rem' } }} // Apply the font size to the selected value
                        renderValue={renderSelectedValues}
                        MenuProps={{
                          PaperProps: {
                            sx: { '& .MuiMenuItem-root': { fontSize: '1.2rem' } } // Apply the font size to menu items
                          }
                        }}
                      >
                        {field.options.map((option) => (
                          <MenuItem 
                            key={option.value} 
                            value={option.value}
                            disabled={isMaxSelected && !selectedOptions.includes(option.value) && option.value !== 'other'}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {renderError()}
                    </FormControl>
                    {isOtherSelected && (
                    <TextField
                      label={`Other ${field.label}`}
                      variant="outlined"
                      name={`${field.id}Other`}
                      placeholder={`Enter other ${field.label.toLowerCase()}`}
                      value={formData[`${field.id}Other`] || ''}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      sx={{ 
                        '& .MuiInputBase-input': { fontSize: '1.2rem' }, // This ensures the input text is the right size
                        '& .MuiInputLabel-root': { fontSize: '1.2rem' } // This ensures the label text is the right size
                      }}
                    />
                  )}

                  </>
                );
              }
                        
          
        

              case 'selectWithOther':
                return (
                  <>
                    <FormControl fullWidth margin="normal" error={!!error}>
                      <InputLabel sx={{ fontSize: '1.2rem' }}>{field.label}</InputLabel>
                      <Select
                        value={formData[field.id] || ''}
                        onChange={handleChange}
                        name={field.id}
                        label={field.label}
                        sx={{ 
                          '& .MuiSelect-select': { fontSize: '1.2rem' } // Apply font size to the selected value
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: { '& .MuiMenuItem-root': { fontSize: '1.2rem' } } // Apply font size to menu items
                          }
                        }}
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.2rem' }}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {renderError()}
                    </FormControl>
                    {formData[field.id] === 'other' && (
                      <TextField
                        label={`Other ${field.label}`}
                        variant="outlined"
                        name={`${field.id}Other`}
                        placeholder={`Enter ${field.label.toLowerCase()}`}
                        value={formData[`${field.id}Other`] || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          '& .MuiInputBase-input': { fontSize: '1.2rem' }, // Apply font size to the input text
                          '& .MuiInputLabel-root': { fontSize: '1.2rem' } // Apply font size to the label
                        }}
                      />
                    )}
                  </>
                );
              
      case 'disclaimer':
        return (
          <>
            <Typography variant="h6" gutterBottom>{field.label}</Typography>
            <Box sx={{ margin: 'normal', padding: '15px', border: '1px solid #ccc', borderRadius: '4px' }}>
              <Typography variant="body1">{field.text}</Typography>
            </Box>
            <FormControlLabel
              control={<Checkbox checked={formData[field.id] || false} onChange={handleChange} name={field.id} />}
              label={field.checkboxLabel}
            />
            {renderError()}
          </>
        );
      default:
        return null;
    }
};

export default FormFieldRenderer;
