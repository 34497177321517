import { useState, useEffect } from 'react';

const useQuizPagination = (totalQuestions, questionsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  // Call scrollToTop when currentPage changes, indicating navigation to a new page
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll to the top
      });
    };

    scrollToTop();
  }, [currentPage]); // Dependency on currentPage ensures this runs only when currentPage changes

  const nextPage = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  return {
    currentPage,
    setCurrentPage,
    nextPage,
    prevPage,
    totalPages
  };
};

export default useQuizPagination;
