import { useState, useEffect } from 'react';
import { shuffleArray } from '../utils/shuffle';
import quizConfig from '../data/quizConfig.json';

const useQuizQuestionsLoader = (quizId) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadQuestions = async () => {
      setLoading(true);
      setError(null);

      try {
        if (!quizConfig[quizId]) {
          throw new Error(`Quiz ID '${quizId}' not found in configuration.`);
        }

        const { questionsFile } = quizConfig[quizId];
        const questionsModule = await import(`../data/${questionsFile}`);

        // Check if the imported module is in the new format
        if (questionsModule.default.quizId === quizId && Array.isArray(questionsModule.default.questions)) {
          // Shuffle the options for each question individually
          const shuffledQuestions = questionsModule.default.questions.map(question => ({
            text: question.text,
            options: shuffleArray(question.options.map(option => ({
              text: option.text,
              traits: option.traits
            })))
          }));
          setQuestions(shuffledQuestions); // Shuffle the questions after shuffling options
        } else {
          // Handle the case where the data is not in the expected format
          throw new Error(`Quiz data for '${quizId}' is not in the expected format.`);
        }
      } catch (err) {
        console.error('Failed to load questions:', err);
        setError('Failed to load questions. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadQuestions();
  }, [quizId]);

  return { questions, loading, error };
};

export default useQuizQuestionsLoader;
