// src/components/NavigationButtons.js
import React from 'react';
import { Button, Box } from '@mui/material';

const buttonStyle = {
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #1976D2 30%, #2196F3 90%)',
  },
  '&:disabled': {
    background: 'linear-gradient(45deg, #BBDEFB 30%, #E3F2FD 90%)',
    color: '#000',
  },
};

const NavigationButtons = ({
  currentPage,
  totalPages,
  onPrevious,
  onNext,
  onSubmit,
  showSubmitButton,
  isCurrentQuestionAnswered,
}) => {
  const handleNextClick = () => {
    onNext(); // Call the onNext function
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4, gap: '20px' }}>
      {currentPage > 1 && (
        <Button variant="contained" sx={buttonStyle} onClick={onPrevious}>
          Previous
        </Button>
      )}

      {!showSubmitButton && currentPage < totalPages && (
        <Button
          variant="contained"
          sx={buttonStyle}
          onClick={handleNextClick} // Use handleNextClick function
          disabled={!isCurrentQuestionAnswered}
        >
          Next
        </Button>
      )}

      {showSubmitButton && (
        <Button variant="contained" sx={buttonStyle} onClick={onSubmit}>
          Submit
        </Button>
      )}
    </Box>
  );
};

export default NavigationButtons;
