import { useState } from 'react';

const useQuiz = (initialQuestions) => {
  const [results, setResults] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionSelect = (option, questionIndex) => {
    setResults((prevResults) => {
      const updatedResults = { ...prevResults };
      const previouslySelectedOption = selectedOptions[questionIndex];

      // Deselect the previously selected option for this question if any
      if (previouslySelectedOption) {
        const trait = Object.keys(previouslySelectedOption.traits)[0]; // Get the trait key
        updatedResults[trait] -= previouslySelectedOption.traits[trait]; // Decrement the marks
      }

      // If selecting a new option (not deselecting), update the scores
      if (option !== previouslySelectedOption) {
        const trait = Object.keys(option.traits)[0]; // Get the trait key
        updatedResults[trait] = (updatedResults[trait] || 0) + option.traits[trait]; // Increment the marks
      }

      // Clean up any score that might have become zero or negative
      Object.keys(updatedResults).forEach((key) => {
        if (updatedResults[key] <= 0) {
          delete updatedResults[key];
        }
      });

      // Update the selected option state
      if (option !== previouslySelectedOption) {
        setSelectedOptions({ ...selectedOptions, [questionIndex]: option });
      } else {
        // If the same option was clicked, remove the selection
        const newSelectedOptions = { ...selectedOptions };
        delete newSelectedOptions[questionIndex];
        setSelectedOptions(newSelectedOptions);
      };
      
      

      return updatedResults;
    });
  };

  const resetQuiz = () => {
    setResults({}); // Reset results to an empty object
    setSelectedOptions({}); // Reset selected options to an empty object
  };

  return { results, selectedOptions, handleOptionSelect, resetQuiz, setSelectedOptions  };
};




export default useQuiz;
