// src/components/QuizQuestion.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import OptionCard from './OptionCard';

const optionLetters = ['A', 'B', 'C', 'D', 'E']; // Add more letters if you have more options

const QuizQuestion = ({ question, options, onOptionSelect, questionNumber, selectedOption }) => {
  const handleSelectOption = (option) => {
    onOptionSelect(option, selectedOption !== option);
  };

  return (
    <Card sx={{ marginBottom: 4, border: '1px solid #ddd', boxShadow: 'none' }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom sx={{ fontSize: '1.25rem', color: '#333' }}>
          {`${questionNumber}. ${question.text}`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {options.map((option, index) => (
            <OptionCard
              key={index}
              option={option}
              onSelect={() => handleSelectOption(option)}
              isSelected={selectedOption === option}
              letter={optionLetters[index]}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuizQuestion;
