import React, { useState, useEffect } from 'react';
import { Box, Typography, Backdrop, CircularProgress } from '@mui/material';
import { supabase } from '../supabaseClient';
import useQuiz from '../hooks/useQuiz';
import useQuizPagination from '../hooks/useQuizPagination';
import useQuizQuestionsLoader from '../hooks/useQuizQuestionsLoader';
import QuizRegistrationForm from './QuizRegistrationForm';
import QuizContent from './QuizContent';
import QuizNavigation from './QuizNavigation';
import ProgressBar from './ProgressBar';
import DialogBox from './DialogBox';
import Layout from './Layout';
import quizConfig from '../data/quizConfig.json';
import { useNavigate } from 'react-router-dom';
import CustomButton from './CustomButton'; 


const Quiz = ({ quizId }) => {
  const quizDetails = quizConfig[quizId] || {};
  const { questions, loading, error } = useQuizQuestionsLoader(quizId);
  const { results, selectedOptions, handleOptionSelect, setSelectedOptions } = useQuiz(questions);
  const questionsPerPage = 1;
  const { currentPage, setCurrentPage, totalPages } = useQuizPagination(questions.length, questionsPerPage);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const navigate = useNavigate();
  const [dialogInfo, setDialogInfo] = useState({ isOpen: false, title: '', content: '', buttonText: 'Close', type: 'default' });
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [registrationData, setRegistrationData] = useState({ name: '', dob: '', class: '' });
  const answeredQuestionsCount = Object.keys(selectedOptions).length;
  const totalQuestions = questions.length; // total number of questions
  const [isCurrentQuestionAnswered, setIsCurrentQuestionAnswered] = useState(false);
  

  


  useEffect(() => {
    setIsQuizCompleted(false);
    setIsQuizStarted(false);
    setDialogInfo({ isOpen: false, title: '', content: '', buttonText: 'Close', type: 'default' });
    document.title = 'Career-9 | ' + (quizDetails.pageTitle || 'Default Quiz Title');
  }, [quizId, quizDetails.pageTitle]);

  useEffect(() => {
    setIsCurrentQuestionAnswered(!!selectedOptions[currentPage - 1]);
  }, [selectedOptions, currentPage]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    // Call scrollToTop on currentPage change
    scrollToTop();
  }, [currentPage]);
  

    
  const handleRegistrationChange = (event) => {
    setRegistrationData({
      ...registrationData,
      [event.target.name]: event.target.value,
    });
  };

  const startQuiz = () => {
    setIsQuizStarted(true);
    setCurrentPage(1); // Reset currentPage to 1 when starting a new quiz
    setSelectedOptions({}); // Reset selectedOptions to an empty object
    // Reset other relevant state, such as results, here
  };
  
  

  const handleSubmit = async () => {
    if (Object.keys(selectedOptions).length === questions.length) {
      const quizType = quizDetails.type; // 'mima' or 'pema'
      const quizDataField = `${quizType}_quiz_data`;
  
      // No need to recalculate results here, as it's already updated by useQuiz hook
  
      // Prepare data for submission to Supabase
      const sessionData = {
        [quizDataField]: { ...registrationData, selectedOptions: selectedOptions, results: results },
      };
  
      // Submit data to Supabase
      const { error } = await supabase.from('user_sessions').insert([sessionData]);
  
      if (error) {
        // Error handling on submission failure
        setDialogInfo({ isOpen: true, title: 'Error', content: 'Failed to submit quiz data. Please try again.', buttonText: 'OK', type: 'error' });
      } else {
        // Successful submission handling
        setIsQuizCompleted(true);
        setDialogInfo({ isOpen: true, title: 'Quiz Submitted', content: 'Your quiz has been submitted successfully.', buttonText: 'OK', type: 'success' });
      }
    } else {
      // Handle case where not all questions are answered
      setDialogInfo({ isOpen: true, title: 'Incomplete Quiz', content: 'Please answer all questions before submitting.', buttonText: 'OK', type: 'warning' });
    }
  };
  
  

  if (loading) {
    return <Layout pageTitle={quizDetails.pageTitle}><Backdrop open={true}><CircularProgress color="inherit" /></Backdrop></Layout>;
  }

  if (error) {
    return <Layout pageTitle={quizDetails.pageTitle}><Typography color="error" sx={{ m: 2 }}>{error}</Typography></Layout>;
  }

  if (isQuizCompleted) {
    const nextQuizId = quizConfig[quizId]?.nextQuizId;
  
    return (
      <Layout pageTitle="Analysis Completed">
        <Box
          sx={{
            textAlign: 'center',
            mt: 4,
            p: 2, // Add padding to the Box element
          }}
        >
          <Typography variant="h5">Thank you for completing the assessment!</Typography>
          <Box sx={{ mt: 2 }}> {/* Add margin-top to create a gap */}
            {nextQuizId ? (
              <div>
                <Box sx={{ mt: 2 }}> {/* Add margin-top to create a gap */}
                  <CustomButton label="Proceed to Next Analysis" onClick={() => navigate(`/${nextQuizId}`)} />
                </Box>
              </div>
            ) : (
              <Typography>You may close this window. We will be in touch with your reports soon!</Typography>
            )}
          </Box>
        </Box>
      </Layout>
    );
  }
  
  
  


  if (!isQuizStarted) {
    return (
      <Layout pageTitle={quizDetails.pageTitle}>
        <Box sx={{ padding: 3 }}>
          <QuizRegistrationForm
            registrationData={registrationData}
            handleRegistrationChange={handleRegistrationChange}
            onSubmit={startQuiz}
          />
        </Box>
      </Layout>
    );
  }

  const firstIndex = (currentPage - 1) * questionsPerPage;
  const currentQuestions = questions.slice(firstIndex, firstIndex + questionsPerPage);

  return (
    <Layout pageTitle={quizDetails.pageTitle} pageDescription={quizDetails.description}>
      <Box sx={{ padding: 3, position: 'relative' }}>

      <ProgressBar 
          currentQuestionIndex={answeredQuestionsCount} 
          totalQuestions={totalQuestions} 
        />

        <QuizContent questions={currentQuestions} handleOptionSelect={handleOptionSelect} selectedOptions={selectedOptions} firstQuestionIndex={firstIndex} />

        <QuizNavigation
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        handleSubmit={handleSubmit}
        isCurrentQuestionAnswered={isCurrentQuestionAnswered} // Pass the prop here
      />




        {dialogInfo.isOpen && (
          <DialogBox isOpen={dialogInfo.isOpen} handleClose={() => setDialogInfo({ ...dialogInfo, isOpen: false })} title={dialogInfo.title} content={dialogInfo.content} buttonText={dialogInfo.buttonText} type={dialogInfo.type} />
        )}
      </Box>
    </Layout>
  );
};

export default Quiz;
