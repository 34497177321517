// useFormState.js
import { useState } from 'react';

const useFormState = (initialState, formFields, isFieldDisabled) => {
    const [formData, setFormData] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        formFields.forEach(field => {
            if (isFieldDisabled && isFieldDisabled(field)) {
                return; // Skip validation for disabled fields
            }

            if (!formData[field.id] || (Array.isArray(formData[field.id]) && formData[field.id].length === 0)) {
                newErrors[field.id] = 'This field is required';
                isValid = false;
            }
        });

        if (!formData.disclaimer) {
            newErrors.disclaimer = 'You must agree to the disclaimer.';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };

    return { formData, formErrors, handleChange, validateForm, setFormErrors };
};

export default useFormState;
