import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Layout = ({ children, pageTitle, pageDescription }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" sx={{ background: 'transparent' }}>
      <Box boxShadow={3} bgcolor="primary.main" color="primary.contrastText" p={2}>
        <Typography variant="h5" textAlign="center">Career-9</Typography>
      </Box>

      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        {pageTitle && (
          <Typography variant="h4" textAlign="center" gutterBottom sx={{ color: 'black' }}> {/* Adjust text color */}
            {pageTitle}
          </Typography>
        )}
        {pageDescription && (
          <Typography variant="body1" textAlign="center" gutterBottom sx={{ color: 'black' }}> {/* Adjust text color */}
            {pageDescription}
          </Typography>
        )}
        {children}
      </Container>

      <Box boxShadow={3} bgcolor="primary.main" color="primary.contrastText" p={2} mt="auto">
        <Typography variant="caption" textAlign="center">career-9.com</Typography>
      </Box>
    </Box>
  );
};

export default Layout;
