import React from 'react';
import QuizQuestion from './QuizQuestion';

const QuizContent = ({ questions, handleOptionSelect, selectedOptions, firstQuestionIndex }) => {
  return (
    <>
      {questions.map((question, index) => (
        <QuizQuestion
          key={index}
          question={question}
          options={question.options}
          onOptionSelect={(option) => handleOptionSelect(option, firstQuestionIndex + index)}
          selectedOption={selectedOptions[firstQuestionIndex + index]}
          questionNumber={firstQuestionIndex + index + 1}
        />
      ))}
    </>
  );
};

export default QuizContent;
