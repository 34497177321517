// QuizRegistrationForm.js
import React from 'react';
import { TextField, MenuItem, Button, Typography, Paper } from '@mui/material';


const QuizRegistrationForm = ({ registrationData, handleRegistrationChange, onSubmit }) => {
  const isFormValid = () => {
    return registrationData.name && registrationData.dob && registrationData.class;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      onSubmit(); // calling the passed onSubmit function
    } else {
      alert('Please fill in all the fields'); // replace with your dialog component if needed
    }
  };

  return (
    <form onSubmit={handleSubmit}>

            {/* Instructions Section */}
            <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
        <Typography variant="h6" gutterBottom>Instructions:</Typography>
        <ul>
          <li>
            <Typography variant="body1">Each question presents a scenario with five options to choose from.</Typography>
          </li>
          <li>
            <Typography variant="body1">Remember, there are no right or wrong answers here.</Typography>
          </li>
          <li>
            <Typography variant="body1">Your unique perspective is what counts.</Typography>
          </li>
          <li>
            <Typography variant="body1">Answer independently without external consultation.</Typography>
          </li>
          <li>
            <Typography variant="body1">Choose the option that immediately appeals to you the most.</Typography>
          </li>
          <li>
            <Typography variant="body1">Ensure you answer all questions, even if some seem similar.</Typography>
          </li>
          <li>
            <Typography variant="body1">Only proceed to the next question after you've answered the current one.</Typography>
          </li>
        </ul>
      </Paper>
      
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        value={registrationData.name}
        onChange={handleRegistrationChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Date of Birth"
        name="dob"
        type="date"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={registrationData.dob}
        onChange={handleRegistrationChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        select
        label="Class"
        name="class"
        variant="outlined"
        value={registrationData.class}
        onChange={handleRegistrationChange}
        fullWidth
        margin="normal"
        required
      >
        {['6', '7', '8', '9', '10', '11', '12'].map((option) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </TextField>
      <Button type="submit" variant="contained" color="primary">Start Quiz</Button>





    </form>
  );
};

export default QuizRegistrationForm;
