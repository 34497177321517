import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Quiz from './components/Quiz';
import RegistrationForm from './components/RegistrationForm'; // Make sure to import the RegistrationForm
import quizConfig from './data/quizConfig.json';
import './App.css';

function App() {
  const [registrationData, setRegistrationData] = useState(null);

  // Handle the completion of the registration form
  const handleRegistrationComplete = (data) => {
    setRegistrationData(data);
    // Here you would determine the next quiz based on registration data
    // For simplicity, let's navigate to the first quiz
    // In a real scenario, you would use the data to determine the correct path
  };

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route
            path="/"
            element={
              registrationData ? (
                <Navigate replace to="/quiz/1" /> // Navigate to the first quiz after registration
              ) : (
                <RegistrationForm onRegistrationComplete={handleRegistrationComplete} />
              )
            }
          />
          {Object.entries(quizConfig).map(([quizId, config]) => (
            <Route key={config.path} path={config.path} element={<Quiz quizId={quizId} />} />
          ))}
          <Route path="*" element={<div>404 - Not Found</div>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
