// src/utils/SupabaseUtility.js
import { supabase } from '../supabaseClient';

export const submitToSupabase = async (formData, pemaQuizData, mimaQuizData, pemaScore, mimaScore) => {
    const sessionData = {
        form_data: formData,
        pema_quiz_data: pemaQuizData,
        mima_quiz_data: mimaQuizData,
        pema_score: pemaScore,
        mima_score: mimaScore,
    };

    const { data, error } = await supabase
        .from('user_sessions')
        .insert([sessionData]);

    if (error) {
        console.error('Error submitting form to Supabase:', error);
        return { error, data }; // Return both error and data for debugging
    } else {
        console.log('Form submitted successfully to Supabase:', data);
        return { data }; // Return data
    }
};
