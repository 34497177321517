// src/components/OptionCard.js
import React from 'react';
import { Paper, Typography, ButtonBase, styled } from '@mui/material';

const getGradient = (isSelected) => {
  return isSelected
    ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
    : 'linear-gradient(45deg, #e0e0e0 30%, #ffffff 90%)';
};

// Custom TouchRipple using styled component
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.03)',
  },
  '& .MuiTouchRipple-root': {
    transitionDuration: '600ms', // Slows down the ripple effect
  },
}));

const OptionCard = ({ option, onSelect, isSelected, letter }) => (
  <StyledButtonBase
    onClick={() => onSelect(option)}
  >
    <Paper
      elevation={isSelected ? 10 : 2}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 25px',
        margin: '10px 0',
        background: getGradient(isSelected),
        borderRadius: '4px',
        cursor: 'pointer',
        transform: isSelected ? 'scale(1.02)' : 'scale(1.01)',
        boxShadow: isSelected ? '0 6px 25px rgba(0,0,0,0.3)' : 'none',
        transition: 'transform 0.4s ease, box-shadow 0.4s ease', // Smoother transition for both transform and box-shadow
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
        width: '100%',
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: isSelected ? 'white' : '#333', marginRight: '10px' }}>
        {letter}
      </Typography>
      <Typography variant="subtitle1" sx={{ flexGrow: 1, textAlign: 'left', color: isSelected ? 'white' : '#333' }}>
        {option.text}
      </Typography>
    </Paper>
  </StyledButtonBase>
);

export default OptionCard;
